<template>
  <div class="w-full md:max-w-5xl bg-white mx-auto">
    <table
      class="table-auto overflow-scroll bg-white rounded-md md:mx-auto md:w-full text-sm text-left text-gray-500"
    >
      <thead class="text-xs text-gray-700 uppercase bg-gray-50">
        <tr class="border">
          <th scope="col" class="px-6 py-3 border-r">SL</th>
          <th scope="col" class="px-6 py-3 border-r">
            <div class="flex min-w-max gap-1 items-center justify-center">
              <div class="bg-indigo-200 text-indigo-500 px-2 py-0.5 rounded-lg">
                Date
              </div>
              <div class="bg-sky-200 text-sky-500 px-2 py-0.5 rounded-lg">
                Time
              </div>
            </div>
          </th>
          <th scope="col" class="px-6 py-3 border-r">
            <div class="flex min-w-max gap-1 items-center justify-center">
              <div class="bg-indigo-200 text-indigo-500 px-2 py-0.5 rounded-lg">
                Chamber
              </div>
              <div class="bg-sky-200 text-sky-500 px-2 py-0.5 rounded-lg">
                Address
              </div>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">Appointment</th>
        </tr>
      </thead>
      <tbody class="">
        <tr
          class="hover:bg-gray-100 bg-white border-b"
          v-for="(schedule, index) in schedules"
          :key="index"
        >
          <td scope="row" class="border-r border-b p-4">{{ ++index }}</td>
          <td class="border-r border-b p-4">
            <div class="flex flex-col gap-4 items-center justify-center">
              <div
                class="py-px px-2 text-sm rounded-lg bg-indigo-100 text-indigo-500"
              >
                {{ schedule.date }}
              </div>
              <div
                class="py-px px-2 text-sm rounded-lg bg-indigo-100 text-sky-500"
              >
                {{ schedule.time_schedule.start_time }} -
                {{ schedule.time_schedule.end_time }}
              </div>
            </div>
          </td>
          <td class="border-r border-b p-4">
            <div class="flex flex-col gap-4 items-center justify-center">
              <div
                class="py-px px-2 text-sm rounded-lg bg-indigo-200 text-brand"
              >
                {{ schedule.chamber_name }},
              </div>
              <div class="px-2 py-px text-sm rounded-lg bg-sky-200 text-brand">
                {{ schedule.chamber_address }}
              </div>
            </div>
          </td>
          <td class="border-r border-b p-4">
            <div class="flex flex-col gap-4 justify-center items-center">
              <div
                v-for="(total, index) in schedule.total_appointments"
                :key="index"
              >
                <span class="text-sky-500"
                  >{{ total }} ({{ support_type[index] }})</span
                >
              </div>
              <div>
                <router-link
                  :to="{
                    name: 'mentorAppointmentIndex',
                    params: { scheduleId: schedule.id },
                  }"
                  class="bg-blue-500 text-white py-2 px-3 rounded-lg"
                  >Appointments</router-link
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("mentor/mentorSchedules", {
      id: this.user,
    });
  },

  computed: {
    schedules() {
      return this.$store.state.mentor.schedules.schedules || "";
    },
    support_type() {
      return this.$store.state.mentor.schedules.support_types || "";
    },
    user() {
      return this.$store.getters["auth/user"].id || "";
    },
  },
};
</script>
